/* -------------------------------------------
    Instagram
---------------------------------------------- */

.instagram {
    position: relative;
    overflow: hidden;
    border-radius: .3rem;
    a {
        display: block;
        position: relative;
        height: 100%;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #000;
            opacity: 0;
            transition: opacity .3s;
            z-index: 1;
        }
        &::after {
            content: '\f16d';
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) rotate(90deg) scale(2);
            font: {
                size: 3rem;
                family: "riode";
            }
            color: #fff;
            opacity: 0;
            transition: opacity .3s, transform .3s;
            z-index: 1;
        }
    }

    img {
        display: block;
        transition: transform .3s;
        width: 100%;
        height: auto;
    }
    &:hover {
        a {
            &::before {
                opacity: .5;
            }
            &::after {
                opacity: 1;
                transform: translate(-50%,-50%) rotate(0deg) scale(1);
            }
        }
        img {
            transform: scale(1.15);
        }
    }
}

.instagram-info {
    .instagram-content {
        position: absolute;
        left: 3rem;
        bottom: 3rem;
        font: {
            size: 1.4rem;
        }
        letter-spacing: 0;
        color: #fff;
        opacity: 0;
        z-index: 2;
        transition: opacity .3s;
        i {
            display: inline-block;
            margin-right: .8rem;
            vertical-align: middle;
            font-size: 1.6rem;
        }
        .d-icon-comments::before {
            font-size: 1em;
        }
        a {
            display: inline-block;
            color: #fff;    
            &:not(:last-child) {
                margin-right: 2rem;
            }

            &::before  {
                content: none;
            }
        }
    }
    a::after {
        content: none;
    }
    &:hover {
        .instagram-content {
            opacity: 1;
        }
    }
}

@include mq('359px' ,max) {
    .instagram-info .instagram-content {
        left: 1rem;
        bottom: 1rem;
    }
}